.App-header {
    height: 60px;
}

@media screen and (min-width: 768px) {
    .App-header {
        height: 100px;
    }
}
  
.App-logo {
    height: 100%;
}

.App-logo-link {
    height: 100%;
}

.sticky-header {
    background-color: #000;
    color: #fff;
    padding: 20px;
}

/* Add a shadow when scrolling down */
.sticky-header.sticky {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    width: calc(100% - 40px);
    z-index: 100;
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.nav ul a {
    text-decoration: none;
    transition: 0.3s;
}

.nav ul a:hover,
.nav ul a:focus {
    text-decoration: underline;
    color: #61dafb;

}

/* Style the menu link */
.nav .skip {
    position: absolute;
    right: 38px;
    top: 38px;
    background: #000;
    overflow: hidden;
    width: 2.5em;
    height: 2.5em;
    text-align: center;
    line-height: 2.5em;
}

.nav .skip img {
    height: 26px;
    width: auto;
}

/* Give the .nav ul a height when it's been opened, i.e. make it show */
.nav ul.open {
    max-height: 20em;
}

/* Hide and position the closed .nav ul */
.nav ul {
    background-color: #222;
    max-height: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 100px;
    transition: max-height 0.25s ease-out;
    width: 100%;
    z-index: 1;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 2rem;
}

/* Style the list items to appear under the menu link */
nav li {
    text-align: right;
}

nav li:first-child a {
    padding-top: 20px;
}

/* Style the list item links for ease of touch on small screens */
nav li a {
    padding: 0 20px 20px;
    display: block;
    color: #fff;
    text-transform: uppercase;
}

@media screen and (min-width: 768px) {
    nav li a {
        background-color: transparent;
        padding-top: 20px;
    }
}

/* On screens larger than 768px, remove the menu link and display all menu items */
@media screen and (min-width: 768px) {
    .nav ul {
        overflow: auto;
        max-height: inherit;
        position: static;
        display: flex;
        justify-content: flex-start;
        background-color: transparent;
    }

    .nav li {
        text-align: left;
        border-top: 0;
        text-align: center;
        display: flex;
        align-items: center;
    }

    .nav li:last-child {
        border: 0;
    }

    .nav .skip {
        display: none;
        visibility: hidden;
    }
}