.expandable-list {
  padding: 0;
  width: 100%;
  max-width: 980px;
}

.list-item {
  border: 1px solid #ddd;
  margin-bottom: 10px;
}

.list-header {
  cursor: pointer;
  color: #000;
  background-color: #ddd;
  font-family: 'Bebas Neue', sans-serif;
  font-size: 2rem;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.list-content {
  display: none;
  padding: 10px;
  text-align: left;
  clear: both;
}

.list-content.active {
  display: block;
}

@media screen and (min-width: 768px) {
  .list-content.active {
    display: flex;
    justify-content: space-between;
  }
}