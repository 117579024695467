footer {
    padding: 60px;
}

footer ul {
 list-style-type: none;
 padding: 0;   
}

.social__icon {
    height: 32px;
}

.social__list li {
    display: inline-block;
    margin: 0 10px;
}