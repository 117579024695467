.App {
  text-align: center;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.App-hero {
  background-image: url(hero-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  font-size: 1.2rem;
}

.App-hero__overlay {
  background-color: rgba(0, 0, 0, .7);
  padding: 30px;
}

.App-hero h1, .App-hero p {
  margin: 20px 0;
  line-height: 1;
}

.heading_block {
  display: block;
  font-size: 2.7rem;
}

.App-hero__pic img {
  width: 90%;
  border: 7px solid #fff;
}

nav {

}

a {
  color: #61dafb;
}

a:hover {
  text-decoration: none;
}

.cta {
  /* background-color: #ffcd45; */
  background-color: #61dafb;
  border-radius: 5px;
  color: #000;
  display: inline-block;
  font-family: 'Bebas Neue', sans-serif;
  padding: 15px;
  text-decoration: none;
  font-size: 1.5rem;
  transition: 0.3s;
}

.cta:hover, .cta:focus {
  background-color: #61a9fb;
}

.container_outer {
  padding: 20px;
}

.container_outer:nth-child(even) {
  background-color: #111;
}

.container_pic {
  overflow: hidden;
}

.container_pic img {
  max-width: 90%;
  border: 10px solid #fff;
}

.fade-in {
	animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 1s both;
}

.fade-in-faster {
  animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) .2s both;
}

.slide-in-top {
	animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s both;
}

.slide-in-bottom {
	animation: slide-in-bottom 0.75s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.fade-in-intersection {
  opacity: 0;
  transition: opacity 1s ease-in;
  margin: 10px;
}


@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

iframe {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .App-hero__overlay {
    padding: 60px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .heading_block {
    font-size: 5rem;
  }

  .App-hero__text {
    text-align: left;
  }

  .App-hero__pic {
    max-width: 33%;
  }

  .container_outer {
    padding: 60px;
  }

  .container_inner {
    display: flex;
    justify-content: center;
    text-align: left;
    align-items: center;
  }

  .container_text {
    max-width: 60%;
    padding-right: 40px;
  }

  .container_pic {
    max-width: 33%;
  }
}