.project__list {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    /* max-width: 1300px; for for column*/
    max-width: 975px;
    margin: 0 auto;
}

.project__item {
    position: relative;
    font-size: 1rem;
    opacity: .1;
    transition: opacity 2s;
    overflow: hidden;
}

.project__item img {
    border: 1px solid #fff;
}

.project__meta {
    display: flex;
    flex-direction: column;
    align-content: space-around;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);
    width: 100%;
    opacity: 0;
    scale: 0;
    transition: 0.3s;
}

.project__item:hover .project__meta {
    opacity: 1;
    scale: 1;
  }

.project__title {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 2rem;
    font-weight: 600;
    margin: 80px auto 0;
}

.project__artist {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 1.8rem;
    display: block;
    margin: 0 auto 5px;
}

.project__genre {
    display: block;
    margin: 0 auto 5px;
}

.project__credits {
    display: block;
    margin: 0 auto 5px;
}

.project-link {
    display: block;
}